import './App.css';
import {Route,BrowserRouter as Router} from 'react-router-dom'
import Terms from "./pages/terms/terms"

function App() {
  return (
      <Router>
        <Route exact path="/terms" component={Terms} />
      </Router>
  );
}

export default App;
